import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalProps, Spinner } from 'react-bootstrap';
import debounce from 'lodash/debounce';
import { ActionMeta, StylesConfig } from 'react-select';
import AsyncSelect from 'react-select/async';
import { useAppSelector } from '../../../../../hooks/redux';
import { fetchTeamsData } from '../../../../../apis/tournament';
import { ModalBox, RoundedAvatar } from '../../../../../components';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../../../../apis/api.config';
import { ClassTeam } from '../../../TournamentClasses/components/ClassesTag/ClassesTag';

type Option = { value: number; label: string; avatar: string | null };

export const customStyles: StylesConfig = {
    control: (base) => ({ ...base, borderRadius: '16px', minHeight: '50px' }),
};

interface TeamModalProps extends ModalProps {
    selected?: ClassTeam[];
}

const teamsToOptions = (teams: ClassTeam[]): Option[] => {
    return teams.map((team) => ({
        value: Number(team.id),
        label: team.name,
        avatar: team.logo,
    }));
};

export const TeamModal = ({ selected = [], ...props }: TeamModalProps) => {
    // State hooks
    const { data: modalData } = useAppSelector((state) => state.modals);
    const [selectedTeams, setSelectedTeams] = useState<ClassTeam[]>([]);
    const { data, isLoading } = useQuery({
        queryKey: ['teams'],
        queryFn: () => fetchTeamsData(),
        placeholderData: keepPreviousData,
    });

    // Derived state
    const options: Option[] = useMemo(() => {
        return teamsToOptions(data || []);
    }, [data]);

    // Translation hooks
    const { t } = useTranslation();

    const prefix = 'modal-';
    const inputPrefix = 'input-';

    // Callback hooks
    const handleSelect = useCallback(
        (newValue: readonly Option[], _actionMeta: ActionMeta<Option>) => {
            const selectedTeams: ClassTeam[] = newValue
                .map((option) => data?.find((team) => team.id === option.value))
                .filter((team) => team !== undefined) as ClassTeam[];
            setSelectedTeams(selectedTeams);
        },
        [data]
    );

    const promiseOptions = debounce((inputValue, cb) => {
        queryClient
            .fetchQuery({
                queryKey: ['teams', inputValue],
                queryFn: () => fetchTeamsData(inputValue),
                staleTime: 1000 * 60 * 5,
            })
            .then((response) => {
                cb(teamsToOptions(response));
            });
    }, 500);

    const handleSave = useCallback(() => {
        const newSelections = selectedTeams.filter(
            (team) => !((modalData.selected as ClassTeam[]) || []).find((om) => om.id === team.id)
        );
        newSelections.forEach((team) => {
            modalData.push({
                id: team.id,
                name: team.name,
                users: team.users || [],
                captain: team.captain || null,
                logo: team.logo || null,
                seed: team.seed || 0,
            });
        });
        props.onHide && props.onHide();
    }, [modalData, selectedTeams, props]);

    return (
        <ModalBox {...props} title={modalData?.title} handleSave={handleSave}>
            {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                    <p className="mb-0 mx-3">{t(prefix + 'userFetch') + '...'}</p>
                    <Spinner size="sm" animation="border" variant="dark" />
                </div>
            ) : (
                <AsyncSelect
                    isMulti
                    name="members"
                    className="basic-multi-select holder-select"
                    classNamePrefix="select"
                    defaultOptions={options}
                    loadOptions={promiseOptions}
                    placeholder={t(inputPrefix + 'placeholderSelect') + '...'}
                    onChange={handleSelect}
                    formatOptionLabel={(data) => (
                        <div className="d-flex align-items-center">
                            <RoundedAvatar avatar={data.avatar} alt="User avatar" name="user" />
                            {data.label}
                        </div>
                    )}
                />
            )}
        </ModalBox>
    );
};
